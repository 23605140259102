import { useState } from 'react';
import { css } from '@emotion/css'
import ResourcePanel from './ResourcePanel';

const ResourceStyle = css`

  .colorlink {
      color: #FFFFFC;
      position: relative; /* Ensure pseudo-element is positioned relative to this */
      padding-right: 12px; /* Space for the arrow */

  }

  .colorlink::after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%) rotate(45deg); /* Properly center and rotate the arrow */
      width: 6px;
      height: 6px;
      border-right: 2px solid #FFFFFC;
      border-bottom: 2px solid #FFFFFC;
      transition: transform 0.1s ease-in-out;
    }
  .rhs ul {
    display: flex; /* Use flexbox to align items */
    align-items: center; /* Vertically align items */
    list-style: none; /* Remove default list styling */
    padding: 0; /* Remove default padding */
    margin: 0; /* Remove default margin */
  }

  .rhs li {
    margin-left: 32px; /* Add spacing between list items - adjust as needed */
  }

  .rhs li:first-child {
    margin-left: 0; /* Remove margin from the first item */
  }
`;

function AppHeaderFull() {
  const [isPanelVisible, setIsPanelVisible] = useState(false);

  const handleClick = () => {
    setIsPanelVisible(!isPanelVisible); // Toggle the panel visibility
  };

  return (
    <>
      <nav
        className={`flex w-full items-center justify-between ${ResourceStyle}`}
        id="nav"
        aria-label='Main Menu'
      >
        <div className="lhs">
          <a href="/">
            <img src="/header/forte-notary-logo-transparent-v2.svg" alt="Forte Notary Logo" className="h-24 w-24 mx-6" />
          </a>
        </div>

        <div className="rhs text-lg">
          <ul role="menubar"> {/* Added ul with menubar role */}
            <li role="presentation">
              <a href="/locations" className="text-[#FFFFFC] hover:text-[#e2ba57]" role="menuitem">Forte Notary Locations</a>
            </li>
            <li role="presentation">
              <a href="/ralphbrooks" className="text-[#FFFFFC] hover:text-[#e2ba57]" role="menuitem">About Ralph Brooks</a>
            </li>
            <li role="presentation">
              <span
                className="colorlink cursor-pointer text-[#FFFFFC]"
                onClick={handleClick}
                role="menuitem"
                aria-haspopup="true" // Indicate that this item has a popup
                aria-expanded={isPanelVisible} // Indicate whether the popup is expanded
              >
                Resources
              </span>
              <ResourcePanel isVisible={isPanelVisible} />
            </li>
            <li role="presentation">
              <a href="/contact" className="text-[#FFFFFC] hover:text-[#e2ba57]" role="menuitem">Contact Us</a>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default AppHeaderFull;