// src/components/profile/ralphbrooksprofile.tsx

import { useState } from 'react';
import AppHeaderMobile from '../header/AppHeaderMobile';
import AppHeaderFull from '../header/AppHeaderFull';
import { getAggregateRating } from '../../components/common/seo/schemaHelpers';
import StarRating from './StarRating';
import { FaPhoneAlt, FaMapMarkerAlt, FaEnvelope } from 'react-icons/fa';
import { css } from '@emotion/css';

/** ======== STYLES ======== **/
const backgroundStyle = css`
  background: url('/about/frisco-darkest-background-sharp-lossy.webp');
  @media (min-width: 1px) and (max-width: 534px) {
    background-size: cover;
    background-position-x: 0rem;
    background-position-y: -2rem;
    max-height: 100vh;
    background-repeat: no-repeat;
  }
  @media (min-width: 535px) and (max-width: 1510px) {
    background-size: cover;
    background-position: 0% 20%;
    max-height: 100vh;
  }
  @media (min-width: 1511px) {
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: -2rem;
    min-height: 60vh;
  }
  @media (min-width: 1536px) {
    .face-image {
      position: relative;
      left: 10rem;
    }
  }
`;

const contentWrapper = css`
  position: relative;
  z-index: 1;
`;

const profileImageStyle = css`
width: 150px;
@media (min-width: 768px) {
  width: 200px;
}
  
  height: auto;
  border-radius: 5px;
  border: 2px solid #e2ba57; /* Gold border */
`;

const mainContentStyle = css``;

const sectionTitle = css`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #e2ba57;
  text-transform: uppercase;
  border-bottom: 1px solid #e2ba57;
  display: inline-block;
`;

// Add these new styles to the existing styles section
const desktopContactContainer = css`
  display: none;
  @media (min-width: 768px) {
    display: block;
    margin: 1.5rem 0;
  }
`;

const desktopContactItem = css`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin: 0.75rem 0;
  color: #e2ba57;
  font-size: 1.1rem;

  svg {
    flex-shrink: 0;
  }
`;

const copyablePhone = css`
  cursor: pointer;
  position: relative;
  
  &:hover::after {
    content: 'Click to copy';
    position: absolute;
    bottom: -1.5rem;
    left: 0;
    background: #2b3645;
    color: #e2ba57;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-size: 0.8rem;
    white-space: nowrap;
  }
`;


const accordionSection = css`
  margin-bottom: 2rem;
`;

const accordionItem = css`
  background-color: #2b3645;
  border: 1px solid #3a4758;
  margin-bottom: 1rem;
  border-radius: 5px;
`;

const accordionHeader = css`
  padding: 1rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #e2ba57;
  font-weight: bold;
`;

const accordionContent = css`
  padding: 1rem;
  color: #fffffc;
`;

const contactAndSocialStyle = css`
  display: flex-column;
  align-items: left;
  align-items: center;

  @media (min-width: 767px) {
    align-items: flex-start;
  }
`;

// Add these styles to the existing styles section
const addressContainer = css`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 1rem 0;

    /* Center on mobile, left-align on larger screens */
    justify-content: center;  
  @media (min-width: 768px) {
    justify-content: flex-start;
  }
`;

const addressLinkStyle = css`
  color: #e2ba57;
  text-decoration: none;
  font-size: 1.1rem;
  transition: opacity 0.2s ease;

  &:hover {
    text-decoration: underline;
    opacity: 0.9;
  }
`;


const buttonGroup = css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1.5rem;


  @media (min-width: 576px) {
    display: none;
  }
`;

const solidButton = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #e2ba57;
  color: #212a37;
  font-weight: 700;
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  transition: background-color 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    background-color: #d1a94f; /* Slightly darker gold for hover */
  }

  /* Space between icon & text */
  svg {
    margin-right: 0.5rem;
    font-size: 1.3rem; /* Default size for icons */
  }
`;


/** ======== COMPONENT ======== **/
const RalphBrooksProfile = () => {
  const { ratingValue, reviewCount } = getAggregateRating(); // e.g. { ratingValue: 4.8, reviewCount: 6 }

  // Initialize each accordion section as open (true)
  const [accordionStates, setAccordionStates] = useState({
    education: true,
    certifications: true,
    professional: true,
  });

  // Logging API endpoint (used for tracking clicks)
  const CALL_LOG_API = "https://call-logger-460102063392.us-central1.run.app/log_call_data";

  // Track phone-copy clicks and copy the phone number to clipboard
  const handleCopyPhoneClick = () => {
    const payload = {
      fullUrl: window.location.href,
      timestamp: Date.now(),
      title: document.title,
      notes: "copied phone number"
    };
    const body = JSON.stringify(payload);
    if (navigator.sendBeacon) {
      const blob = new Blob([body], { type: 'application/json' });
      navigator.sendBeacon(CALL_LOG_API, blob);
    } else {
      fetch(CALL_LOG_API, {
        method: "POST",
        body: body,
        headers: { "Content-Type": "application/json" },
        keepalive: true,
      }).catch((err) => console.error("Copy phone logging failed", err));
    }
    navigator.clipboard.writeText('4692968682');
  };

  // Mobile: When the user clicks the call button, log the event with note "call"
  const handleMobileCallClick = () => {
    const payload = {
      fullUrl: window.location.href,
      timestamp: Date.now(),
      title: document.title,
      notes: "call"
    };
    const body = JSON.stringify(payload);
    if (navigator.sendBeacon) {
      const blob = new Blob([body], { type: 'application/json' });
      navigator.sendBeacon(CALL_LOG_API, blob);
    } else {
      fetch(CALL_LOG_API, {
        method: "POST",
        body: body,
        headers: { "Content-Type": "application/json" },
        keepalive: true,
      }).catch((err) => console.error("Mobile call logging failed", err));
    }
    // Allow the tel: link to proceed as usual.
  };


  const toggleAccordion = (section: keyof typeof accordionStates) => {
    setAccordionStates((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  return (
    <div className={`about-page pt-5 bg-[#212A37] text-[#FFFFFC] min-h-screen flex flex-col `}>
      {/* Header Section */}
      <header className="fixed top-0 left-0 w-full p-4 flex justify-between items-center border-b border-[#FFFFFC] bg-[#212A37]/80 backdrop-blur z-[100]">
        <div className="hidden md:flex w-full">
          <AppHeaderFull />
        </div>
        <div className="md:hidden flex items-center justify-between w-full">
          <AppHeaderMobile />
        </div>


      </header>


      {/* Hero Section */}
      <section className={`hero-section ${backgroundStyle} relative`}>
        <div className="profile-container container mx-auto px-4 py-8">
          {/* Guarantee bar */}
          {/* <div className="text-center mt-20">
          <GuaranteeBar linkTargetId="satisfaction-guaranteed-section" />
        </div> */}
          Profile Section
          <div className="profile-section pt-24 flex flex-col md:flex-row items-center md:items-start">

            {/* Profile Image */}
            <div className="md:w-1/3 flex justify-center md:justify-start">
              <img
                src="/brooks-face.webp"
                alt="Ralph Brooks, Mobile Notary in Frisco, TX"
                className={`face-image ${profileImageStyle}`}
              />
            </div>
            {/* Profile Details */}
            <div className="md:w-2/3 md:pl-12 mt-6 md:mt-0 text-center md:text-left">
              <h1 className="text-4xl font-bold mb-2">Ralph Brooks</h1>
              {/* Title + Rating row */}
              <div className="flex flex-col md:flex-row md:items-center md:justify-between">
                <h2 className="text-2xl font-semibold mb-2 relative inline-block">
                  <span className="block md:inline">Frisco Mobile Notary</span>

                  {/* Desktop: Show rating on same line */}
                  <span className="hidden md:inline ml-4">
                    <a
                      href="https://g.co/kgs/pJjqaXd"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="View Google Reviews"
                      style={{ textDecoration: 'none' }} // remove underline
                    >

                      <StarRating rating={ratingValue} reviewCount={reviewCount} />
                    </a>
                  </span>

                </h2>

              </div>



              {/* Desktop Contact Info */}
              <div className={desktopContactContainer}>
                {/* NEW ADDRESS SECTION */}
                <div className={addressContainer}>
                  <FaMapMarkerAlt className="text-[#e2ba57]" />
                  <a
                    href="https://maps.apple.com/?q=5013+Madison+Drive+Frisco+TX"
                    className={addressLinkStyle}
                    onClick={(e) => {
                      // Fallback for non-Apple devices
                      if (!navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
                        e.preventDefault();
                        window.open('https://www.google.com/maps/search/?api=1&query=5013+Madison+Drive+Frisco+TX', '_blank');
                      }
                    }}
                  >
                    5013 Madison Drive, Frisco, TX
                  </a>
                </div>
                <div className={desktopContactItem}>
                  <FaEnvelope className="text-[#e2ba57]" />
                  <a href="mailto:ralph.brooks@fortenotary.com" className={addressLinkStyle}>
                    ralph.brooks@fortenotary.com
                  </a>
                </div>
                <div className={desktopContactItem}>
                  <FaPhoneAlt className="text-[#e2ba57]" />
                  <span
                    className={copyablePhone}
                    onClick={handleCopyPhoneClick}
                  >
                    (469) 296-8682
                  </span>
                </div>
              </div>


              {/* Mobile: Show rating below */}
              <div className="md:hidden mt-2 block">
                {/* address mobile */}
                <div className={addressContainer}>
                  <FaMapMarkerAlt className="text-[#e2ba57]" />
                  <a
                    href="https://maps.apple.com/?q=5013+Madison+Drive+Frisco+TX"
                    className={addressLinkStyle}
                    onClick={(e) => {
                      // Fallback for non-Apple devices
                      if (!navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
                        e.preventDefault();
                        window.open('https://www.google.com/maps/search/?api=1&query=5013+Madison+Drive+Frisco+TX', '_blank');
                      }
                    }}
                  >
                    5013 Madison Drive, Frisco, TX
                  </a>
                </div>
                <a
                  href="https://g.co/kgs/pJjqaXd"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="View Google Reviews"
                  style={{ textDecoration: 'none' }} // remove underline
                >
                  <StarRating rating={ratingValue} reviewCount={reviewCount} />
                </a>
              </div>



              <p className="text-lg md:text-xl mt-4 mb-4">
                Need documents notarized quickly? I come to you—home, office, or anywhere in DFW.
              </p>


              <div className={`contact-and-social ${contactAndSocialStyle}`}>
                <div className={buttonGroup}>
                  {/* Call Now Button (with phone number text) */}
                  <a
                    href="tel:+14692968682"
                    className={solidButton}
                    aria-label="Call Forte Notary at (469) 296-8682"
                    onClick={handleMobileCallClick}
                  >
                    <FaPhoneAlt />
                    Call (469) 296-8682
                  </a>

                </div>
                {/* 
                If you want LinkedIn or other social icons, you can put them here, e.g.: 
                <div className={socialIcons}>
                  <a
                    href="https://www.linkedin.com/in/ralphbrooks"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="LinkedIn"
                  >
                    <FaLinkedin />
                  </a>
                </div>
                */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <main className={`flex-grow flex flex-col items-center ${contentWrapper}`}>
        <div className="container mx-auto px-4 py-4">
          {/* Main content section */}
          <section className={`main-content ${mainContentStyle}`}>
            {/* Specialization Section */}
            <section className="mt-2">
              <h3 className={sectionTitle}>Specializations</h3>
              <ul className="mt-4 space-y-4">


                <li className="flex flex-col md:flex-row md:items-center">
                  <strong className="text-[#e2ba57] text-lg mb-2 md:mb-0 md:mr-4">
                    Will Signing Services
                  </strong>
                  <p className="text-md text-[#FFFFFC]">
                    I partner with attorneys to ensure legally valid will execution and provide witnesses as needed.
                  </p>
                </li>
                <li className="flex flex-col md:flex-row md:items-center">
                  <strong className="text-[#e2ba57] text-lg mb-2 md:mb-0 md:mr-4">
                    Loan Signing Services
                  </strong>
                  <p className="text-md text-[#FFFFFC]">
                    Fast, error-free handling of buyer packages, seller packages, and refinances.
                  </p>
                </li>
                <li className="flex flex-col md:flex-row md:items-center">
                  <strong className="text-[#e2ba57] text-lg mb-2 md:mb-0 md:mr-4">
                    Medical Documents
                  </strong>
                  <p className="text-md text-[#FFFFFC]">
                    Reliable notary services for healthcare documents like Medical POAs and Advance Directives.
                  </p>
                </li>
              </ul>
            </section>

            {/* BEGIN NEW CRITICAL DOCUMENTS SECTION (REPLACEMENT) */}
            <section className="mt-12">
              <h3 className={sectionTitle}>Notarize Without The Wait</h3>

              <h4 className="block text-2xl font-bold text-[#e2ba57] leading-tight mt-6 mb-2">
                Your time is your most valuable asset.
              </h4>
              <p className="mt-4 text-lg leading-relaxed">
                Whether you're a busy professional, a title company, a law firm,
                or a family facing an unexpected situation, you need a notary who is reliable,
                responsive, and understands the importance of getting things done right.
              </p>

              <h4 className="text-xl text-[#e2ba57] font-semibold mt-8">
                A White-Glove Notary Experience
              </h4>
              <p className="mt-4 text-lg leading-relaxed">
                Forte Notary brings white-glove notary services directly to you,
                wherever you are in DFW. I understand that needing a notary can arise
                during challenging or time-sensitive circumstances. That's why I'm
                committed to providing the utmost professionalism, discretion,
                and empathy in every interaction.
              </p>

              <h4 className="text-xl text-[#e2ba57] font-semibold mt-8">
                Real Estate &amp; Finance Expertise
              </h4>
              <p className="mt-4 text-lg leading-relaxed">
                My background in real estate finance, including work with executives
                from firms like BlackRock and Goldman Sachs, has instilled in me a deep
                appreciation for accuracy and confidentiality. My MBA from UCLA Anderson
                further ensures I grasp the complexities of legal documents and the
                critical importance of detail-oriented execution.
              </p>


              <h4 className="text-xl text-[#e2ba57] font-semibold mt-8">Bottom Line</h4>
              <p className="mt-4 text-lg leading-relaxed">
                <strong>
                  I eliminate the hassle of traditional notarization so you can
                  stay focused on what matters most.
                </strong>
              </p>

              <p className="mt-8 text-lg leading-relaxed">
                My NNA background check and profile can be found here:{' '}
              </p>
              <p className="mt-4 text-lg leading-relaxed">
                <a
                  href="https://www.signingagent.com/profile/161842286"
                  className="underline text-[#e2ba57]"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.signingagent.com/profile/161842286
                </a>
              </p>
            </section>
            {/* END NEW CRITICAL DOCUMENTS SECTION (REPLACEMENT) */}


            {/* Credentials Section */}
            <section className="mt-12">
              <h3 className={sectionTitle}>Credentials</h3>
              <div className={accordionSection}>
                {/* Education Accordion */}
                <div className={accordionItem}>
                  <div
                    className={accordionHeader}
                    onClick={() => toggleAccordion('education')}
                    role="button"
                    tabIndex={0}
                  >
                    <span className="text-lg">Education</span>
                    <span>{accordionStates.education ? '-' : '+'}</span>
                  </div>
                  {accordionStates.education && (
                    <div className={accordionContent}>
                      <ul className="list-none ml-0">
                        <li className="mb-4">
                          <strong className="text-md text-[#e2ba57]">
                            UCLA Anderson School of Management
                          </strong>
                          <div className="text-sm text-[#FFFFFC] mt-1">
                            Master of Business Administration (MBA)
                          </div>
                        </li>
                        <li className="mb-4">
                          <strong className="text-md text-[#e2ba57]">
                            Central Michigan University
                          </strong>
                          <div className="text-sm text-[#FFFFFC] mt-1">
                            Bachelor of Science
                          </div>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>

                {/* Certifications Accordion */}
                <div className={accordionItem}>
                  <div
                    className={accordionHeader}
                    onClick={() => toggleAccordion('certifications')}
                    role="button"
                    tabIndex={0}
                  >
                    <span className="text-lg">Certifications</span>
                    <span>{accordionStates.certifications ? '-' : '+'}</span>
                  </div>
                  {accordionStates.certifications && (
                    <div className={accordionContent}>
                      <ul className="list-none ml-0">
                        <li className="mb-4">
                          <strong className="text-md text-[#e2ba57]">
                            National Notary Association
                          </strong>
                          <div className="text-sm text-[#FFFFFC] mt-1">
                            <p>
                              Verified Notary Commission &amp; Background Check
                              <br />
                              <a
                                style={{ color: '#e2ba57' }}
                                href="https://www.signingagent.com/profile/161842286"
                              >
                                View my NNA Verified Profile
                              </a>
                            </p>
                          </div>
                        </li>
                        <li className="mb-4">
                          <strong className="text-md text-[#e2ba57]">
                            Notary2Pro Elite Graduate
                          </strong>
                          <div className="text-sm text-[#FFFFFC] mt-1">
                            <p>
                              Certified Loan Signing Agent
                              <br />
                              <span className="text-[#bfbfbf] text-sm">
                                Verification requires entering my email
                                <br />
                                <em>ralph.brooks@fortenotary.com</em> at{' '}
                                <a
                                  style={{ color: '#e2ba57' }}
                                  href="https://notary2pro.com/looking-for-a-notary/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  notary2pro.com
                                </a>
                              </span>
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>

                {/* Professional Background Accordion */}
                <div className={accordionItem}>
                  <div
                    className={accordionHeader}
                    onClick={() => toggleAccordion('professional')}
                    role="button"
                    tabIndex={0}
                  >
                    <span className="text-lg">Professional Background</span>
                    <span>{accordionStates.professional ? '-' : '+'}</span>
                  </div>
                  {accordionStates.professional && (
                    <div className={accordionContent}>
                      <ul className="list-none ml-0">
                        <li className="mb-4">
                          <div className="text-sm text-[#FFFFFC]">
                            <strong className="text-[#e2ba57]">Real Estate Finance:</strong> Four
                            years of finance experience including assisting in securing $62 million in mezzanine debt financing for the Trump Waikiki hotel.
                          </div>
                        </li>
                        <li className="mb-4">
                          <div className="text-sm text-[#FFFFFC]">
                            <strong className="text-[#e2ba57]">Strategy &amp; IT Consulting:</strong>{' '}
                            18 years advising Fortune 500 companies such as AT&amp;T, Toyota, and
                            Blue Cross Blue Shield of Oklahoma.
                          </div>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </section>

            {/* 100% SATISFACTION SECTION */}
            {/* <section className="mt-12">
              <h3 className={sectionTitle}>Your Satisfaction, Guaranteed</h3>
              <p className="mt-4 text-lg leading-relaxed">
                At Forte Notary, your confidence in our service is paramount. We strive to ensure every notarization is executed flawlessly.
              </p>
              <p className="mt-2 text-lg leading-relaxed">
                If a notarization cannot be completed due to document irregularities or issues that prevent lawful notarization, you will not be charged for our services.
              </p>
              <p className="mt-2 text-lg leading-relaxed">
                This commitment reflects a dedication to providing you with a risk-free and satisfactory experience. Your trust is our top priority.
              </p>
            </section> */}

            {/* NEW CONTACT SECTION */}
            <section className="mt-12">
              <h3 className={sectionTitle}>Contact</h3>
              <p className="mt-4 text-lg leading-relaxed">
                Need immediate assistance or have questions? Contact me today!
              </p>
              <p className={` text-lg leading-relaxed`}>
                <br className="mt-4" />
                <strong>Ralph Brooks, Notary Public</strong> <br />
                <span>Phone:</span>{' '}
                <a href="tel:+14692968682" className="underline text-[#e2ba57]">
                  (469) 296-8682
                </a>
                <br />
                <span>Email:</span>{' '}
                <a href="mailto:ralph.brooks@fortenotary.com" className="underline text-[#e2ba57]">
                  ralph.brooks@fortenotary.com
                </a>
              </p>
            </section>
            {/* END NEW CONTACT SECTION */}
          </section>
        </div>
      </main>
    </div>
  );
};

export default RalphBrooksProfile;
