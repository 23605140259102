// src/app/frontend/src/pages/resources.tsx

// This serves at https://fortenotary.com/resources

import SEO from '../components/common/seo/SEO';
import Breadcrumbs from '@/components/common/seo/Breadcrumbs';
import AppHeaderMobile from '../components/header/AppHeaderMobile';
import AppHeaderFull from '../components/header/AppHeaderFull'; // Import AppHeaderFull

const ResourceLibrary = () => {
  const breadcrumbs = [
    { name: 'Main', url: '/' },
    { name: 'Resources', url: '/resources' },

  ];
  return (
    <>
      <SEO
        title='Resource Library'
        description="Get answers to questions about the notary process."
        includeOrg={false}
        canonicalUrl='https://fortenotary.com/resources'
      />
      <Breadcrumbs url="https://fortenotary.com" breadcrumbs={breadcrumbs} />
      <div className="p-8 bg-[#212A37] text-[#FFFFFC] min-h-screen flex flex-col">
        {/* Header Section */}
        <header className=" fixed top-0 left-0 right-0 w-full p-4 flex justify-between items-center border-b border-[#FFFFFC] bg-[#212A37]/80 backdrop-blur">
          <div className="hidden md:flex w-full">
            <AppHeaderFull />  {/* Use AppHeaderFull for desktop */}
          </div>
          <div className="md:hidden flex items-center justify-between w-full">
            <AppHeaderMobile />  {/* Use AppHeaderMobile for mobile */}
          </div>
        </header>
        {/* Main Content Section */}
        <main>
          <div className="flex-grow flex items-center justify-center">
            {/* card */}
            <div className="bg-[#212A37] mt-8 p-8 max-w-lg w-full">
              <h1 className="text-3xl font-bold mt-20 mb-6">Resources Library</h1>
              <h2>Browse resources that can help you learn about the notarization process</h2>
              <p className="mb-4">The resources library is a work in progress</p>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default ResourceLibrary;
