import { motion } from 'framer-motion';
import { FaTimes, FaChevronRight } from 'react-icons/fa';
import { useState } from 'react';
import ReactDOM from 'react-dom'; // Import ReactDOM
import ResourcesMobileMenu from './ResourcesMobileMenu';

interface SubmenuProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
}

const Submenu: React.FC<SubmenuProps> = ({ isOpen, onClose, title }) => {
  const [showResourcesMenu, setShowResourcesMenu] = useState(false);

  const handleResourcesClick = () => {
    setShowResourcesMenu(true);
  };

  const handleBackToSubmenu = () => {
    setShowResourcesMenu(false);
  };

  const submenuContent = (
    <>
      <motion.div
        initial={{ x: '100%' }}
        animate={{ x: isOpen ? '0%' : '100%' }}
        transition={{
          type: 'spring',
          stiffness: 300,
          damping: 30,
          duration: 0.2,
          ease: 'easeOut',
        }}
        className="fixed inset-0 w-full h-full bg-[#1E2A3A] z-[1000]"
        style={{ transformOrigin: 'right' }}
      >
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-white text-xl"
          aria-label="Close"
        >
          <FaTimes />
        </button>

        <div className="text-white text-2xl font-bold p-4">{title}</div>

        <div className="flex flex-col items-start p-8 text-white">
          <a href="/locations" className="mb-4">Forte Notary Locations</a>
          <a href="/ralphbrooks" className="text-[#FFFFFC] mb-4">About Ralph Brooks</a>
          {/* Resources Section */}
          <div
            className="flex items-center justify-between w-full mb-4 cursor-pointer"
            onClick={handleResourcesClick}
          >
            <span>Resources</span>
            <FaChevronRight className="ml-auto text-[#0084ff]" />
          </div>
          {/* Contact Us */}
          <a href="/contact" className="mb-4">Contact Us</a>

        </div>
      </motion.div>

      <ResourcesMobileMenu
        isOpen={showResourcesMenu}
        onBack={handleBackToSubmenu}
      />
    </>
  );

  return isOpen
    ? ReactDOM.createPortal(submenuContent, document.body)
    : null;
};

export default Submenu;
